import './Select.scss';

import React from 'react';
import classnames from 'classnames';

import downArrow from 'assets/images/down-arrow.svg';

interface Props {
  value?: string | null;
  onChange(event: React.SyntheticEvent<HTMLSelectElement>): void;
  children: React.ReactNode;
  placeholder?: string;
}

const Select: React.FC<Props> = (props) => {
  const { value, children, placeholder, ...selectProps } = props;

  return (
    <select
      {...selectProps}
      value={value || ''}
      className={classnames('CoreSelect', { 'CoreSelect--default': !value })}
      style={{ backgroundImage: `url(${downArrow})` }}
      placeholder={placeholder}
    >
      {children}
    </select>
  );
};

export default Select;
