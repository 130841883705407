import './ThumbnailFrameworkGraph.scss';

import React from 'react';
import { tree as d3Tree } from 'd3-hierarchy';
import { Size, EdgeInsets } from 'utils/geometry';

import {
  linkPath,
  transformCoordinates
} from 'components/tree-diagrams/d3-extensions';
import { stratifyFramework } from 'components/tree-diagrams/framework-attributes';
import { Framework, Term } from 'types';

interface Props {
  framework: Framework;
  terms: Term[];
  width: number;
  height: number;
}

/**
 * A small thumbnail version of the framework graph without labels or any
 * interaction. Intended to be drawn at small sizes.
 */
export default class ThumbnailFrameworkGraph extends React.PureComponent<Props> {
  treeLayout = (
    width: number,
    height: number,
    terms: Term[],
    framework: Framework
  ) => {
    const size = new Size(width, height);
    const margin = new EdgeInsets(1, 1, 1, 1);
    const contentRect = size.rect.inset(margin);

    const treeLayout = d3Tree().size(contentRect.size.toArrayReversed())(
      stratifyFramework<Framework | Term>(framework, terms)
    );

    transformCoordinates(treeLayout, ({ x, y }) => ({
      x: contentRect.x + y,
      y: contentRect.y + x
    }));

    return treeLayout;
  };

  render() {
    const { width, height } = this.props;
    const rootNode = this.treeLayout(
      width,
      height,
      this.props.terms,
      this.props.framework
    );

    const nodes = rootNode.descendants();

    if (this.props.terms.length === 0) {
      return null;
    }

    return (
      <svg
        className="ThumbnailFrameworkGraph"
        viewBox={`0 0 ${width} ${height}`}
        width={width}
        height={height}
        aria-hidden
      >
        {nodes.slice(1).map((node) => (
          <path
            key={node.id}
            className="link"
            d={linkPath(node, node.parent!)}
          />
        ))}

        {nodes.map((node) => (
          <g
            key={node.id}
            className="node"
            transform={`translate(${node.x}, ${node.y})`}
          >
            <rect
              x={-1}
              y={-1}
              width={2}
              height={2}
              style={{ fill: this.props.framework.color }}
            />
          </g>
        ))}
      </svg>
    );
  }
}
