import './VideoEmbed.scss';

import React from 'react';

interface Props {
  src: string;
  width: number;
  height: number;
}

const VideoEmbed: React.FC<Props> = (props) => {
  const { src, width, height } = props;

  return (
    <div
      className="CoreVideoEmbed"
      style={{ paddingBottom: `${(height / width) * 100}%` }}
    >
      <iframe
        className="CoreVideoEmbed-iframe"
        src={src}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    </div>
  );
};

export default VideoEmbed;
