import './RangeInput.scss';

import React from 'react';

interface Props {
  min: number;
  max: number;
  value: number;
  step?: number | any;
  onChange(event: React.SyntheticEvent<HTMLInputElement>): void;
}

const RangeInput: React.FC<Props> = (props) => {
  const { ...inputProps } = props;

  return (
    <div className="RangeInput">
      <input {...inputProps} type="number" className="RangeInput-textInput" />

      <input {...inputProps} type="range" className="RangeInput-slider" />
    </div>
  );
};

RangeInput.defaultProps = {
  step: 'any'
};

export default RangeInput;
