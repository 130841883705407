import { stratify as d3Stratify } from 'd3-hierarchy';

import { Term } from 'types';

/**
 * Checks if two terms are related. `false` if terms are in the same framework.
 */
export function termsAreRelated(termA: Term, termB: Term): boolean {
  if (termA.relatedTerms == null || termA.frameworkID === termB.frameworkID) {
    return false;
  }
  return (
    termA.relatedTerms.find(
      (relatedTerm) => relatedTerm.identifier === termB.identifier
    ) != null
  );
}

/**
 * Stratify a framework's terms into a hierarchical data structure for rendering
 * as a tree, diagram.
 *
 * The array of terms must be exactly the terms for the framework. If any are
 * missing or extraneous it will fail.
 */
export function stratifyFramework<
  T extends { frameworkID?: number; identifier: number; parentID?: number }
>(root: T, terms: T[]): d3.HierarchyNode<T> {
  return d3Stratify<T>()
    .id((d) => (d.frameworkID != null ? d.identifier.toString() : 'framework'))
    .parentId((d) =>
      d.frameworkID != null ? d.parentID?.toString() || 'framework' : null
    )([...terms, root]) as d3.HierarchyNode<T>;
}
