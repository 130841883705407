import './PrintButton.scss';

import React from 'react';

const PrintButton: React.FC = () => {
  return (
    <button className="PrintButton no-print" onClick={() => window.print()}>
      Print Page
    </button>
  );
};

export default PrintButton;
