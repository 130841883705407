import './TextButton.scss';

import React from 'react';

interface Props {
  children: React.ReactNode;
  onClick(event: React.SyntheticEvent<HTMLButtonElement>): void;
}

const TextButton: React.FC<Props> = (props) => {
  const { children, ...buttonProps } = props;

  return (
    <button className="TextButton" {...buttonProps}>
      {children}
    </button>
  );
};

export default TextButton;
