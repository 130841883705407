export function degreesToRadians(degrees: number): number {
  return (degrees * Math.PI) / 180.0;
}

export function radiansToDegrees(radians: number): number {
  return (radians * 180) / Math.PI;
}

export class Point {
  x: number;
  y: number;

  constructor(x: number, y: number) {
    this.x = x;
    this.y = y;
  }

  toString(): string {
    return `(${this.x},${this.y})`;
  }
}

export class PolarPoint {
  angle: number;
  radius: number;

  constructor(angle: number, radius: number) {
    this.angle = angle;
    this.radius = radius;
  }

  toPoint(): Point {
    return new Point(
      Math.cos(this.angle) * this.radius,
      Math.sin(this.angle) * this.radius
    );
  }
}

/// Defines a rectangular size by width and height.
export class Size {
  width: number;
  height: number;

  constructor(width: number, height: number) {
    this.width = width;
    this.height = height;
  }

  /// An array of the width and height, a format used by D3.
  toArray(): [number, number] {
    return [this.width, this.height];
  }

  /// An array of the height and width, a (very specific) format used by D3.
  toArrayReversed(): [number, number] {
    return [this.height, this.width];
  }

  /// A rectangle with this size with it's origin at (0, 0)
  get rect(): Rect {
    return new Rect(0, 0, this.width, this.height);
  }

  /// Size reduced by the given insets.
  inset(insets: EdgeInsets): Size {
    return new Size(this.width - insets.width, this.height - insets.height);
  }

  /// A size with the minimum value of the receiver and given size in both
  /// dimensions.
  min(size: Size): Size {
    return new Size(
      Math.min(this.width, size.width),
      Math.min(this.height, size.height)
    );
  }

  /// A size with the maximum value of the receiver and given size in both
  /// dimensions.
  max(size: Size): Size {
    return new Size(
      Math.max(this.width, size.width),
      Math.max(this.height, size.height)
    );
  }

  rectAt(x: number, y: number): Rect {
    return new Rect(x, y, this.width, this.height);
  }

  static zero = new Size(0, 0);
}

/// Represents a rectangle in terms of the x and y position of it's top left
/// corner, width and height.
export class Rect {
  x: number;
  y: number;
  width: number;
  height: number;

  constructor(x: number, y: number, width: number, height: number) {
    this.x = x;
    this.y = y;
    this.width = width;
    this.height = height;
  }

  /// The x position of the right edge of the rectangle
  get maxX(): number {
    return this.x + this.width;
  }

  /// The y position of the bottom edge of the rectangle
  get maxY(): number {
    return this.y + this.height;
  }

  get size(): Size {
    return new Size(this.width, this.height);
  }

  /// A new rectangle reduced on each side by the values of the inset.
  inset(insets: EdgeInsets): Rect {
    return new Rect(
      this.x + insets.left,
      this.y + insets.top,
      this.width - insets.width,
      this.height - insets.height
    );
  }

  insetTop(inset: number): Rect {
    return this.inset(new EdgeInsets(inset, 0, 0, 0));
  }

  insetRight(inset: number): Rect {
    return this.inset(new EdgeInsets(0, inset, 0, 0));
  }

  insetBottom(inset: number): Rect {
    return this.inset(new EdgeInsets(0, 0, inset, 0));
  }

  insetLeft(inset: number): Rect {
    return this.inset(new EdgeInsets(0, 0, 0, inset));
  }

  static zero = new Rect(0, 0, 0, 0);
}

export class EdgeInsets {
  top: number;
  right: number;
  bottom: number;
  left: number;

  constructor(top: number, right: number, bottom: number, left: number) {
    this.top = top;
    this.right = right;
    this.bottom = bottom;
    this.left = left;
  }

  get width(): number {
    return this.left + this.right;
  }

  get height(): number {
    return this.top + this.bottom;
  }

  get inverse(): EdgeInsets {
    return new EdgeInsets(-this.top, -this.right, -this.bottom, -this.left);
  }
}
