import './CheckboxField.scss';

import React from 'react';

import Checkbox from './Checkbox';
import InputLabel from './InputLabel';

type Props = {
  label: string;
  value?: string;
  indeterminate?: boolean;
  checked: boolean;
  onChange(event: React.SyntheticEvent<HTMLInputElement>): void;
};

const CheckboxField: React.FC<Props> = (props) => {
  const { label, ...inputProps } = props;

  return (
    <label className="CheckboxField">
      <Checkbox className="CheckboxField-input" {...inputProps} />
      <div className="CheckboxField-label">
        <InputLabel>{label}</InputLabel>
      </div>
    </label>
  );
};

export default CheckboxField;
