import './InputLabel.scss';

import React from 'react';

type Props = {
  children: string;
};

const InputLabel: React.FC<Props> = (props) => {
  const { children } = props;

  return <div className="InputLabel">{children}</div>;
};

export default InputLabel;
