import './SmallButton.scss';

import React from 'react';
import classnames from 'classnames';

interface Props
  extends React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  > {
  selected?: boolean;
}
const SmallButton: React.FC<Props> = (props) => {
  const { children, selected, onClick, ...buttonProps } = props;

  return (
    <button
      {...buttonProps}
      className={classnames('SmallButton', {
        'SmallButton--selected': selected
      })}
      onClick={onClick}
    >
      {children}
    </button>
  );
};

export default SmallButton;
