interface TreePointNode {
  x: number;
  y: number;
  each: (coordinate: any) => any;
}

/**
 * Apply a transformation to each node in a D3 hierarchy. Use to transform
 * and rotate a tree from the initial arbitrary cooridante space into the
 * rendering space.
 */
export function transformCoordinates<T extends TreePointNode>(
  rootNode: T,
  transform: (node: T) => { x: number; y: number }
) {
  rootNode.each((node: any) => {
    const { x, y } = transform(node);
    node.x = x;
    node.y = y;
  });
}

interface Point {
  x: number;
  y: number;
}

/**
 * Create a path 'd' attribute drawing a curved line from one point to another.
 * Intended for drawing the links in a tree diagram.
 */
export function linkPath(a: Point, b: Point): string {
  return `M${a.x},${a.y}C${(a.x + b.x) / 2},${a.y},${(a.x + b.x) / 2},${b.y},${
    b.x
  },${b.y}`;
}
