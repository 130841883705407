import './terms.scss';

import React from 'react';
import { graphql, Link } from 'gatsby';

import * as Core from 'components/core';
import Layout from 'components/layout/SplitLayout';
import ThumbnailFrameworkGraph from 'components/tree-diagrams/ThumbnailFrameworkGraph';

import { Framework, Term } from 'types';

interface Props {
  data: {
    framework: Framework;
    allTerms: {
      terms: Term[];
    };
  };
}

const FrameworkTermsPage: React.FC<Props> = (props) => {
  const { framework } = props.data;
  const { terms } = props.data.allTerms;
  const definedTerms = terms.filter((t) => t.includeInThesaurus);

  return (
    <Layout>
      <div>
        <Link to={`/frameworks/${framework.identifier}`}>
          <div className="FrameworkTermsPage-framework">
            <div className="FrameworkTermsPage-framework-thumbnail">
              <ThumbnailFrameworkGraph
                framework={framework}
                terms={terms}
                width={34}
                height={44}
              />
            </div>
            <h1>{framework.name}</h1>
          </div>
        </Link>
        <div className="FrameworkTermsPage-terms">
          {definedTerms.map((term) => (
            <div key={term.identifier} className="FrameworkTermsPage-term">
              <Link to={`/terms/${term.identifier}`}>
                <h2 className="FrameworkTermsPage-term-name">{term.name}</h2>

                <div className="FrameworkTermsPage-term-definition">
                  <Core.Markdown>{term.definition}</Core.Markdown>
                </div>
              </Link>
            </div>
          ))}
        </div>
        <div className="FrameworkTermsPage-backLink no-print">
          <Link to={`/frameworks/${framework.identifier}`}>
            &larr; Back to {framework.name}
          </Link>
        </div>
      </div>
    </Layout>
  );
};

export default FrameworkTermsPage;

// OPTIMIZE: Filter the terms to only those in this framework
export const query = graphql`
  query FrameworkTermsQuery($frameworkID: Int!) {
    framework: frameworksJson(identifier: { eq: $frameworkID }) {
      identifier
      name
      color
    }
    allTerms: allTermsJson(filter: { frameworkID: { eq: $frameworkID } }) {
      terms: nodes {
        identifier
        name
        frameworkID
        parentID
        definition
        includeInThesaurus
      }
    }
  }
`;
