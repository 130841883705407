import './PrintBackground.scss';

import React from 'react';

interface Props {
  color?: string;
}

const PrintBackground: React.FC<Props> = (props) => {
  const { color = '#D3D3D3' } = props;

  return (
    <svg
      className="PrintBackground"
      width="1px"
      height="1px"
      viewBox="0 0 1 1"
      preserveAspectRatio="none"
    >
      <rect x={0} y={0} width={1} height={1} fill={color} />
    </svg>
  );
};

export default PrintBackground;
