import './InputField.scss';

import React from 'react';

import InputLabel from './InputLabel';

type Props = {
  label: string;
  children: React.ReactNode;
};

const InputField: React.FC<Props> = (props) => {
  const { label, children } = props;

  return (
    <div className="InputField">
      <div className="InputField-label">
        <InputLabel>{label}</InputLabel>
      </div>
      {children}
    </div>
  );
};

export default InputField;
