import React, { useEffect, useRef } from 'react';

interface Props
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  indeterminate?: boolean;
}

/**
 * Wrapper around <input type="checkbox" /> adding support for 'indeterminate'
 * state.
 */
const Checkbox: React.FC<Props> = (props) => {
  const { indeterminate, ...inputProps } = props;

  const element = useRef<HTMLInputElement | null>(null);

  useEffect(() => {
    if (element.current) {
      element.current.indeterminate = indeterminate || false;
    }
  }, [indeterminate]);

  return <input {...inputProps} type="checkbox" ref={element} />;
};

Checkbox.defaultProps = {
  indeterminate: false,
  checked: false
};

export default Checkbox;
